export default theme => ({
    root: {
        maxWidth: '24rem',
        '@media (min-width: 600px)': {
            maxWidth: '30rem',
        },
        '@media (min-width: 1245px)': {
            maxWidth: '45rem',
        },
    }
})